@import './reset.scss';
@import './fonts.scss';
@import './override-style.scss';

body {
  font-family: "AvertaCyrillic-Regular", sans-serif;
  color: var(--color-neutralGray600);
}

.l_container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-width: 1140px;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  margin: 0 auto;
}


