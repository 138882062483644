@font-face {
  font-family: "AvertaCyrillic-Bold";
  src: url("/assets/fonts/AvertaCyrillic-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: "AvertaCyrillic-SemiBold";
  src: url("/assets/fonts/AvertaCyrillic-SemiBold.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "AvertaCyrillic-Regular";
  src: url("/assets/fonts/AvertaCyrillic-Regular.ttf");
  font-weight: normal;
}

.text-bold {
  font-family: "AvertaCyrillic-Bold", sans-serif;
  font-weight: 700;
}

.text-semi-bold {
  font-family: "AvertaCyrillic-SemiBold";
}

.text-regular {
  font-family: "AvertaCyrillic-Regular", sans-serif;
  font-weight: 400;
}
.font-size-xxxs {
  font-size: 0.6875rem; // 11px
}
.font-size-xxs {
  font-size: 0.8125rem; // 13px
}
.font-size-xs {
  font-size: 0.875rem; // 14px
}
.font-size-small {
  font-size: 1rem; //16px
}
.font-size-medium {
  font-size: 1.1875rem; //19px
}
.font-size-xxl {
  font-size: 1.375rem; // 22px
}
.font-size-large {
  font-size: 1.4375rem; // 23px
}
.font-size-xl {
  font-size: 1.6875rem; // 27px
}
.font-size-xxxl {
  font-size: 2.5rem; // 40px
}
.font-size-huge {
  font-size: 3rem; // 48px
}

.text-capitalize {
  text-transform: capitalize;
}

