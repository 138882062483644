@mixin isMobile {
  @media only screen and (max-width: 819px) {
    @content;
  }
}
  
@mixin isDesktop {
  @media only screen and (min-width: 820px) {
    @content;
  }
}