/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import './app/styles/global';
@import '~@angular/cdk/overlay-prebuilt.css';
@include mat.core();

@import '~@angular/material/theming';
@import './app/styles/mixins';

// Define a palette for the neutral gray colors
$neutral-gray-palette: mat-palette($mat-grey, 800, 500, 300);

// Create the theme
$custom-theme: mat-light-theme(
  $neutral-gray-palette, // primary palette
  $neutral-gray-palette, // accent palette
  $neutral-gray-palette  // warn palette
);

// Include the theme styles
@include angular-material-theme($custom-theme);

// omnids-svg-icon {
//   vertical-align: middle;
// }

.text-center {
    text-align: center;
}

@keyframes ripple-effect {
  from {
    transform: scale(0);
    opacity: 1;
  }
  to {
    transform: scale(4);
    opacity: 0;
  }
}

.dialog-default-container {
  .mat-dialog-container {
    padding: var(--space-sLarge);
    border-radius: var(--border-radius-XL);
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@mixin dialog-container-desktop {
  animation: none !important;
  padding: var(--space-sZero);
  border-top-left-radius: var(--border-radius-large);
  border-bottom-left-radius: var(--border-radius-large);
  border-top-right-radius:  var(--border-radius-none);
  border-bottom-right-radius:  var(--border-radius-none);
}

.dialog-bottom-sheet-container {
  animation: slideInFromRight 0.5s ease-out;
  .mat-dialog-container {
    @include dialog-container-desktop;

    @include isMobile {
      border-radius: var(--border-radius-none);
    }
  }
}

.dialog-bottom-sheet-mobile {
  animation: slideInFromRight 0.5s ease-out;

  @include isMobile {
    animation: slideInFromBottom 0.5s ease-out;
  }

  .mat-dialog-container {
    @include dialog-container-desktop;

    @include isMobile {
      border-top-left-radius: var(--border-radius-XL);
      border-top-right-radius:  var(--border-radius-XL);
      border-bottom-left-radius: var(--border-radius-none);
      border-bottom-right-radius:  var(--border-radius-none);
    }
  }
}

